import React, { Component } from 'react';
import Footer from "../components/footer";
import Navigation from "../components/navigation"
import api from '../api' // Manages the axios api handling
import { Link } from "react-router-dom";
import Tagline from '../components/tagline';
import { Header } from '../components/Header';

const FilterCategory = (props) => (
  <li>
    <a
      href="#/"
      className={props.className}
      key={props.dataGroup}
      data-group={props.dataGroup}
      onClick={() => props.setCategory(props.dataGroup)}
    > {props.dataGroupText} </a>
  </li>
)

class WorksCard extends Component {
  render() {
    const { projects, base_url, category, index } = this.props; // Load states locally as constants, to avoid using "this.state"
    const url_banner = base_url + this.props.projects.ProjectImages[0].url
    const datagroup = "[\"" + category + "\"]"; // for some reason it isn't equivalent

    return (
      <div className="item column-2" data-groups={datagroup}>
        <a className="image-link-home" href={`${url_banner}`} title="Project Title">
          <div className="zoom-icon"><i className="ion-ios-search" /></div>
          <div className="img">
            <img className="img-fluid" src={`${url_banner}`} alt="img" />
          </div>
        </a>
        <div className="info">
          <h5 className="head-text-img">{projects.ProjectName}</h5>
          {/* button */}
          <Link
            className="wrap-button-link small-button-link"
            onClick={() => (window.location.href = "/projects_" + projects.ProjectName)}
            to={"/projects_" + projects.ProjectName}
          >
            <span>see more</span>
            <span className="button-link">see more</span>
          </Link>
        </div>
      </div>
    );
  }
};


const CategorySidebar = (props) => (
  <div className="col-md-12 col-lg-3">
    <div className="sidebar">
      <div className="sidebar__inner">
        <ul id="filter">
          {
            /* Create a list of the project cateogries */
          }
          <FilterCategory className="filter-item" dataGroup="all" dataGroupText="All" setCategory={props.setCategory} />
          {/* "filter-item active-filter" */}
          {props.project_categories.map((project_category, index) =>
          <FilterCategory
            key={index}
            className="filter-item"
            dataGroup={project_category.Slug}
            dataGroupText={project_category.CategoryName}
            setCategory={props.setCategory} />)}
        </ul>
      </div>
    </div>
  </div>
);


const Presentation = (props) => {
  console.log(props.worksVideoLink)
    return (
      <div className="presentation-section">
        <a className="test-popup-link" href={props.worksVideoLink}>
          <div className="play-button">
            <i className="ion-ios-play" />
          </div>
        </a>
        <span>Play Presentation</span>
      </div>
    )
};

const GridPortfolio = (props) => (
  <div className="wrap-grid-portfolio">
    <div id="grid_ia_custom" className="row portfolio-gutters no-gutters">
      {props.projects.filter(({
        project_categories}) =>
      props.category === project_categories[0].Slug || props.category === "all")
      .map((project, index) =>
      <WorksCard
        key={project.id}
        projects={project}
        index={index}
        base_url={props.base_url}
        category={project.project_categories[0].Slug} />)}
    </div>
  </div>
);


class WorkSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "all",
    };
  }

  setCategory = (category) => {
    console.log(category)
    this.setState({
      category: category
    });
  }

  render() {
    const { projects, base_url, works, project_categories,works_content } = this.props;
    return (

      <section className="works-section sections-pd bg-black">
        <div className="word-row-section word-row-white"><span>{works_content.worksPageTitle}</span></div>
        <div className="emement-bg" />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="h3-white title-section">{works_content.worksPageTitle}</h3>
              <h4 className="h4-white subtitle-section">{works_content.worksPageSubtitle}</h4>
            </div>
          </div>
          <div className="row portfolio-section">
            <CategorySidebar setCategory={this.setCategory} project_categories={project_categories} />
            <div className="col-md-12 col-lg-9">
              <GridPortfolio category={this.state.category} projects={projects} base_url={base_url}/>
            </div>
          </div>
        </div>
      </section>
    )
  }
};

class Works extends Component {
  constructor(props) {
    // Gives the "App" all the "Component" super powers
    super(props); // Inherits all the models which exist in Component

    this.state = {
      homepage: this.props.homepage,
      base_url: this.props.base_url,
      projects: this.props.projects,
      about : this.props.about,
      works: [],
      works_content : [],
      project_categories: [],
      cache_id: this.props.cache_id
    };
  }

  componentDidMount() {
    window.fnInitMain();
    this.getDetails('works', 'works-content');
    this.getDetails('works_content', 'works-content');
    this.getDetails('project_categories', 'project-categories');
  }

  getDetails = (assigned_state, url) => {
    // Retrieve the data from localStorage
    // Reference: https://www.robinwieruch.de/local-storage-react
    const retrievedObject = localStorage.getItem(assigned_state);
    const parsedObject = JSON.parse(retrievedObject);

    // If there is no data in the localStorage, then get it
    if (parsedObject === null) {
      api.getData(url)
        .then(results => {
          const strapi_api_get_data = results.data;
          localStorage.setItem('cache_id', this.state.cache_id);
          localStorage.setItem(assigned_state, JSON.stringify(strapi_api_get_data));
          this.setState({ [assigned_state]: strapi_api_get_data });
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
    // The object existed in localStorage, so let's use it
    else {
      this.setState({ [assigned_state]: parsedObject });
    }
  };

  render() {
    const { homepage, base_url, projects, works, project_categories, about ,works_content} = this.state; // Load states locally as constants, to avoid using "this.state"

    return (
      <div>
        <Navigation />
        <Header homepage={homepage} base_url={base_url} page_title="Works" />
        <Tagline taglineH2FirstLine="We Create Products That Make" taglineH2SecondLine="People Happy" />
        <WorkSection projects={projects} base_url={base_url} works={works} project_categories={project_categories} works_content={works_content}/>
        <Presentation worksVideoLink={works_content.worksVideoLink}/>
        <Tagline taglineH2FirstLine="Let's work together" button_text="contact us" destination="contact" />
        <Footer SocialLinks={homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={false} />
      </div>
    )
  }
}

export default Works;

