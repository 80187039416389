import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "./styles.css";
import "keen-slider/keen-slider.min.css";
import Card from "./card";

// https://keen-slider.io/examples/#navigation

export default props => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 4,
    mode: "free",
    spacing: 15,
    loop: true,
    duration: 1000,

    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    }
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true);
    });

    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 2000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <>
      <div className="clients-section bg-yellow">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wrap-content">
                {/* Swiper */}
                <div ref={sliderRef} className="keen-slider">

                  {props.merchants.map(merchants => (
                    <Card key={merchants.id} id={merchants.id} merchants={merchants.ClientLogo[0]} base_url={props.base_url} text="a" />
                  ))}

                </div>
                {/* /.swiper-container */}
              </div>
              {/* /.wrap-content */}
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

    </>
  );
};
