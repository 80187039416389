import React, { Component } from "react";
import Footer from "../components/footer";
import Navigation from "../components/navigation"
import Tagline from '../components/tagline';
import api from '../api'
import { Header } from '../components/Header';

import Services from '../components/services';
import Loading from '../components/loading';
import { Link } from "react-router-dom";
import SocialFooter from '../components/SocialFooter';

const AboutSection = (props) => {
  if (props.about.length === 0) {
    return null;
  }
  return (
    <section className="about-section sections-pd bg-black">
      <div className="word-row-section word-row-white">
        <span>{props.about.aboutTitle}</span>
      </div>
      <div className="emement-bg" />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="h3-white title-section">{props.about.aboutTitle}</h3>
            <h4 className="h4-white subtitle-section">
              {props.about.aboutSubtitle}
            </h4>
            <p> {props.about.AboutText} </p>
          </div>
          <div className="col-md-5">
            <img className="about-animation-img img-fluid" src="assets/img/home/about-section/1.png" alt="" />
          </div>
        </div>
        {
          /* /.row */
        }
      </div>
      {
        /* /.container */
      }
    </section>)
};



const NameBar = (props) => (
  <div className="col-md-4 team-tab-text">
    <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
      <a className="nav-link2 active" data-toggle="pill" href="#v-pills-team-1" role="tab" aria-controls="v-pills-home" aria-selected="true">
        1. {props.Name}
      </a>
      <a className="nav-link2" data-toggle="pill" href="#v-pills-team-2" role="tab" aria-controls="v-pills-profile" aria-selected="false">
        2. {props._Name}
      </a>
      <a className="nav-link2" data-toggle="pill" href="#v-pills-team-3" role="tab" aria-controls="v-pills-messages" aria-selected="false">
        3. {props.__Name}
      </a>
      <a className="nav-link2" data-toggle="pill" href="#v-pills-team-4" role="tab" aria-controls="v-pills-settings" aria-selected="false">
        4. {props.___Name}
      </a>
    </div>
  </div>
);

const TabPanel = (props) => {
    return (
      <div>
        <div className="wrap-team-img">
          <img className="team-img" src={props.base_url + props.Team.TeamMemberPicture.url} alt="img" />
        </div>
        <TeamTabContent Team={props.Team} />
        <div className="number-tab">{props.Index}</div>

      </div>
    )
};

const TeamTabContent = (props) => (
  <div className="team-tab-content">
    <h4 className="h4-white">{props.Team.Name}</h4>
    <p>
      <span>{props.Team.Position}</span>
      {props.Team.Description}
    </p>
    <SocialFooter SocialLinks={props.Team} />
  </div>
);

const Team = (props) => {
  if (props.about.length === 0) { // Keep loading until API get returns data
    return (
      <Loading />
    )
  }
  else {
    return (
      <section className="team-section sections-pd bg-yellow">
        <div className="word-row-section word-row-black">
          <span>Our Team</span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="h3-black title-section">Our Team</h3>
            </div>
          </div>
          <div className="row team-tab">
            <NameBar
              Name={props.about.Team[0].Name}
              _Name={props.about.Team[1].Name}
              __Name={props.about.Team[2].Name}
              ___Name={props.about.Team[3].Name} />
            <div className="col-md-8">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="v-pills-team-1" role="tabpanel">
                  <TabPanel base_url={props.base_url} Team={props.about.Team[0]} Index="1" />
                </div>
                <div className="tab-pane fade" id="v-pills-team-2" role="tabpanel">
                  <TabPanel base_url={props.base_url} Team={props.about.Team[1]} Index="2" />
                </div>
                <div className="tab-pane fade" id="v-pills-team-3" role="tabpanel">
                  <TabPanel base_url={props.base_url} Team={props.about.Team[2]} Index="3" />
                </div>
                <div className="tab-pane fade" id="v-pills-team-4" role="tabpanel">
                  <TabPanel base_url={props.base_url} Team={props.about.Team[3]} Index="4" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section >
    )
  }
};

const Presentation = (props) => {
  console.log(props.AboutVideoLink)
    return (
      <div className="presentation-section">
        <a className="test-popup-link" href={props.AboutVideoLink}>
          <div className="play-button">
            <i className="ion-ios-play" />
          </div>
        </a>
        <span>Play Presentation</span>
      </div>
    )
};

class About extends Component {
  constructor(props) {
    // Gives the "App" all the "Component" super powers
    super(props); // Inherits all the models which exist in Component

    this.state = {
      homepage: this.props.homepage,
      base_url: this.props.base_url,
      about: this.props.about,
      cache_id: this.props.cache_id
    };
  }

  componentDidMount() {
    window.fnInitMain();
  }

  render() {
    const { homepage, base_url, about } = this.state; // Load states locally as constants, to avoid using "this.state"

      return (
        <div>
          <Navigation />
          <Header homepage={homepage} base_url={base_url} page_title="About Us" />
          <Tagline taglineH2FirstLine="WE LOVE WHAT WE DO" />
          <AboutSection about={about} />
          <Services homepage={homepage} />
          {/* <Team about={about} base_url={base_url} /> */}
          {/* <Presentation AboutVideoLink={about.AboutVideoLink}/> */}
          <Tagline taglineH2FirstLine="We craft beautiful and unique digital experiences" button_text="See Our Works" />
          <Footer SocialLinks={homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={false} />
        </div>
      );
  }
}

export default About;
