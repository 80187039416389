import React, { Component } from "react";
import { Link } from "react-router-dom";

class RecentWorksCard extends Component {
    render() {
        const { work, base_url, index } = this.props; // Load states locally as constants, to avoid using "this.state"
        const url_banner = base_url + work.WorkImage.url;
        const url_big = base_url + work.WorkBigImage.url;

        return (
            <div className="swiper-slide">
                <a
                    // className="image-link-home"
                    href={"/projects_" + index} //{`${url_big}`}
                    title={work.WorkHeadline}
                >
                    {/* <div className="zoom-icon">
                        <i className="ion-ios-search" />
                    </div> */}
                    <div className="img">
                        <img
                            className="img-fluid"
                            src={`${url_banner}`}
                            alt="img"
                        />
                    </div>
                </a>
                <div className="info">
                    <h5 className="head-text-img">{work.WorkHeadline}</h5>
                    <Link
                        className="wrap-button-link small-button-link"
                        onClick={() => (window.location.href = "/projects_" + work.WorkTitle)}
                        to={"/projects_" + work.WorkTitle}
                    >
                        <span>see more</span>
                        <span className="button-link">see more</span>
                    </Link>
                </div>
            </div>
        );
    }
};



export default props => {
    return (
        < section className="recent-works-section sections-pd bg-yellow" >
            <div className="word-row-section word-row-black">
                <span>Recent Works</span>
            </div>
            <div className="emement-bg" />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className="h3-black title-section">Recent Works</h3>
                    </div>
                </div>
            </div>
            {/* /.container */}
            <div className="container-fluid">
                <div className="row">
                    {/* Slider main container */}
                    {/* Swiper */}
                    <div className="swiper-container recent-works-slider">
                        <div className="swiper-wrapper">

                            {props.works.map((work,index) => (
                                <RecentWorksCard key={work.id} work={work} base_url={props.base_url} index={index}/>
                            ))}

                        </div>
                        {/* /.swiper-wrape */}
                        {/* Add Pagination */}
                        <div className="swiper-pagination" />
                    </div>
                    {/* /.swiper-container */}
                </div>
                {/* /.row */}
            </div>
            {/* /.container-fluid */}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Link
                            className="wrap-button-link"
                            onClick={() => (window.location.href = "/works")}
                            to="/works"
                        >
                            <span>view all works</span>
                            <span className="button-link">view all works</span>
                        </Link>
                    </div>
                </div>
            </div>
        </section >
    )
}