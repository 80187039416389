import React from "react";
import "./card.css";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles(() => ({
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default props => {
    const classes = useStyles();

    if (props.merchants === undefined)
    {
        return (null)
    }

    const url = props.base_url + props.merchants.url;

    return (
        <div className={"keen-slider__slide number-slide"}>
            <ButtonBase className={classes.image} disabled='false'>
              <img className={classes.img} alt="complex" src={`${url}`} />
            </ButtonBase>
        </div>
    );
};