import React, { Component } from 'react';

const ServiceDescriptions = (props) => (
  <div className="col-md-6 order-md-1 order-2">
    <div className="tab-content" id="v-pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="v-pills-home"
        role="tabpanel"
        aria-labelledby="v-pills-home-tab"
      >
        <p>
          <span>{props.homepage.ServiceTabs[0].servicesSubHeading}</span>{props.homepage.ServiceTabs[0].servicesText}
        </p>
        <div className="number-tab">01</div>
      </div>
      <div
        className="tab-pane fade"
        id="v-pills-profile"
        role="tabpanel"
        aria-labelledby="v-pills-profile-tab"
      >
        <p>
          <span>{props.homepage.ServiceTabs[1].servicesSubHeading}</span>{props.homepage.ServiceTabs[1].servicesText}
        </p>
        <div className="number-tab">02</div>
      </div>
      <div
        className="tab-pane fade"
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
      >
        <p>
          <span>{props.homepage.ServiceTabs[2].servicesSubHeading}</span>{props.homepage.ServiceTabs[2].servicesText}
        </p>
        <div className="number-tab">03</div>
      </div>
      <div
        className="tab-pane fade"
        id="v-pills-settings"
        role="tabpanel"
        aria-labelledby="v-pills-settings-tab"
      >
        <p>
          <span>{props.homepage.ServiceTabs[3].servicesSubHeading}</span>{props.homepage.ServiceTabs[3].servicesText}
        </p>
        <div className="number-tab">04</div>
      </div>
    </div>
  </div>
);

const ServiceHeading = (props) => (
  <div className="row">
    <div className="col-md-8 col-sm-12">
      <h3 className="h3-black title-section">
        {props.homepage.servicesTitle}
      </h3>
      <h4 className="h4-black subtitle-section">
        {props.homepage.servicesSubtitle}
      </h4>
      <p className="p-black">{props.homepage.servicesTitleText}</p>
    </div>
  </div>
);

const ServiceHeadings = (props) => (
  <div className="col-md-6 order-md-2 order-1">
    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
        {"1. "+props.homepage.ServiceTabs[0].servicesTabHeading}
      </a>
      <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
        {"2. " + props.homepage.ServiceTabs[1].servicesTabHeading}
      </a>
      <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
        {"3. " + props.homepage.ServiceTabs[2].servicesTabHeading}
      </a>
      <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
        {"4. " + props.homepage.ServiceTabs[3].servicesTabHeading}
      </a>
    </div>
  </div>
);

class Services extends Component {
  render() {
    const { homepage } = this.props;
    return (
      <section className="services-section sections-pd bg-white">
        <div className="word-row-section word-row-black">
          <span>{homepage.servicesTitle}</span>
        </div>
        <div className="emement-bg" />
        <div className="container">
          <ServiceHeading homepage={homepage} />
          <div className="row services-tab">
            <ServiceDescriptions homepage={homepage} />
            <ServiceHeadings homepage={homepage} />
          </div>
        </div>
      </section>
    )
  }
};

export default Services;