import React from "react";
import TestimonialCard from "./testimonialcard";

export default props => {
    const { base_url, testimonials } = props; // Load states locally as constants, to avoid using "this.state"

    return (
        <section className="tst-section sections-pd bg-white">
            <div className="word-row-section word-row-black">
                <span>Testimonials</span>
            </div>
            <div className="emement-bg" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h3 className="h3-black title-section">Testimonials</h3>
                        {/* <h4 className="h4-black subtitle-section">
                            In dictum quam eget metus hendrerit, accumsan finibus orci
                            egestas.
                        </h4> */}
                    </div>
                </div>
                {/* /.row */}
                <div className="row tst-slider">
                    <div className="col-md-10 offset-md-1">
                        {/* Swiper */}
                        <div className="swiper-container testimonials-slider">
                            <div className="swiper-wrapper">
                                {testimonials.map(testimonial => (
                                <TestimonialCard key={testimonial.id} base_url={base_url} testimonials={testimonial}/>
                                ))}
                            </div>
                            {/* /.swiper-wrapper */}
                        </div>
                        {/* /.swiper-container */}
                        {/* Add Pagination */}
                        <div className="counter-testimonials">
                            <div className="swiper-pagination custom-pagination-testimonials counter counter-dark" />
                        </div>
                    </div>
                    {/* /.col-md-10 .offset-md-1 */}
                </div>
                {/* /.row .tst-slider */}
            </div>
            {/* /.container */}
        </section>)
}