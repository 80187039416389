import React from "react";


export default props => {

    const { base_url, testimonials } = props; // Load states locally as constants, to avoid using "this.state"
    const url = base_url + testimonials.testimonialImage[0].url;

    return (
        <div className="swiper-slide">
            <div className="slider-item">
                <img
                    src={`${url}`}
                    alt="img"
                />
                <div className="test-txt">
                    <h4 className="h4-black">{testimonials.testimonialName}</h4>
                    <p className="p-black">
                        {testimonials.testimonialText}
                    </p>
                </div>
            </div>
        </div>
    );
};