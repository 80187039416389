import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (

        <nav>
          <div className="menu-logo" />
          {/* navigation icon*/}
          <div className="wrap-nav">
            <div className="icon-nav">
              <span className="icon-nav-1" />
              <span className="icon-nav-2" />
            </div>
          </div>
          <div className="mob-nav">
            <div className="full-nav">
              <div className="menu-bg">
                <div className="menu-active-bg menu-bg home_1" />
                <div className="menu-bg about_2" />
                <div className="menu-bg works_3" />
                <div className="menu-bg news_4" />
                <div className="menu-bg contacts_5" />
              </div>
              <div className="full-nav-links restart-demo basic-staggering-demo">
                <Link
                  className="nav-img el animated delay-01s menu-active"
                  data-menu-bg="home_1"
                  onClick={() => (window.location.href = "/")}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className="nav-img el animated delay-01s"
                  data-menu-bg="about_2"
                  onClick={() => (window.location.href = "/about")}
                  to="/about"
                >
                  About
                </Link>
                <Link
                  className="nav-img el animated delay-01s"
                  data-menu-bg="works_3"
                  onClick={() => (window.location.href = "/works")}
                  to="/works"
                >
                  Works
                </Link>
                {/* <Link
                  className="nav-img el animated delay-01s"
                  data-menu-bg="news_4"
                  onClick={() => (window.location.href = "/news")}
                  to="/news"
                >
                  News
                </Link> */}
                <Link
                  className="nav-img el animated delay-01s"
                  data-menu-bg="contacts_5"
                  onClick={() => (window.location.href = "/contact")}
                  to="/contact"
                >
                  Contact
                </Link>
              </div>
              <div className="top-layer" />
            </div>
            {/* /.full-nav */}
          </div>
          {/* /.mob-nav */}
        </nav>



  );
}

export default withRouter(Navigation);