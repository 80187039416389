import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://cms.royalty-club.com/', // users proxy "cors-anywhere.herokuapp.com" https://medium.com/@dtkatz/3-ways-to-fix-the-cors-error-and-how-access-control-allow-origin-works-d97d55946d9
});

// GET request
export default {
    getData: (url) =>
        instance({
            'method': 'GET',
            'url': url
        }),

    // POST data
    postData: (email, name, message) =>
        instance({
            'method': 'POST',
            'url': '/email',
            'params': {
                'email': email,
                'name': name,
                'message': message
            },
        })
}

// https://rapidapi.com/blog/axios-react-api-tutorial/