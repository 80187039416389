import React from "react";
import { Link } from "react-router-dom";


class SocialFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="social-footer">
                    <a className="wrap-button-link small-button-link"
                    href={this.props.SocialLinks.FB}
                    target="_blank"
                    rel="noopener noreferrer">
                        <span>FB</span>
                        <span className="button-link">FB</span>
                    </a>

                    <a className="wrap-button-link small-button-link"
                    href={this.props.SocialLinks.IN}
                    target="_blank"
                    rel="noopener noreferrer">
                        <span>IN</span>
                        <span className="button-link">IN</span>
                    </a>

                    <a className="wrap-button-link small-button-link"
                    href={this.props.SocialLinks.TW}
                    target="_blank"
                    rel="noopener noreferrer">
                        <span>TW</span>
                        <span className="button-link">TW</span>
                    </a>
            </div>
        );
    }
}

export default SocialFooter;