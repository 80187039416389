
import React from "react";
import { Link } from "react-router-dom";

export default props => {
return(
    <section className="about-section sections-pd bg-black">
      <div className="word-row-section word-row-white">
        <span>{props.About.aboutTitle}</span>
      </div>
      <div className="emement-bg" />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="h3-white title-section">
              {props.About.aboutTitle}
            </h3>
            <h4 className="h4-white subtitle-section">
              {props.About.aboutSubtitle}
            </h4>
            <p>{props.About.aboutText}</p>
            <Link
              className="wrap-button-link"
              onClick={() => (window.location.href = "/about")}
              to="/about"
              >
              <span>read a little more</span>
              <span className="button-link">read more</span>
            </Link>
          </div>
          {
            /* /.col-md-6 */
          }
          <div className="col-md-6">
            <img className="about-animation-img img-fluid" src={props.base_url + props.About.aboutFloatingImage.url} alt={props.About.aboutFloatingImage.url} />
          </div>
        </div>
        {
          /* /.row */
        }
      </div>
      {
        /* /.container */
      }
    </section>
  )
    }
  
  