import React from "react";

function Logo(props) {
    return (
        <div className="main-logo"> <img
            src={
                props.base_url +
                props.url
            } alt="logo" />
        </div>

    );
}

export default Logo;