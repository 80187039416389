import React, { Component, useState } from "react";
import Footer from '../components/footer';
import Navigation from "../components/navigation"
import { useForm } from "react-hook-form";
import axios from 'axios'
import { Header } from '../components/Header';

// const Header = () => (
//   <header className="header-pages">
//     <div className="main-logo">
//       <img src="assets/custom-icon/logo.svg" alt="logo" />
//             sollers
//           </div>
//     <div className="pages-bg">
//       <div className="group-pages">
//         <div className="word-row-pages">
//           <span>Contacts</span>
//         </div>
//       </div>
//     </div>
//     <div className="page-name">
//       <span>Contacts</span>
//     </div>
//   </header>
// );



class Tagline extends React.Component {
  render() {
    return (
      <div className="tagline-section bg-yellow">
        <div className="container text-center">
          <h2>We craft beautiful and unique digital experiences</h2>
        </div>
      </div>
    );
  }
}



const TaglineBase = () => (
  <section className="tagline-section bg-yellow">
    <div className="container text-center">
      <h2>If you have questions, advice, wishes, write to us</h2>
    </div>
  </section>
);


const errorMessage = error => {
  return <p>{error}</p>;
};


const ContactForm = (props) => {

  const { handleSubmit, register, errors } = useForm();
  const [api_response, setApiResponse] = useState("");

  const onSubmit = form_data => {
    try {
      axios.post(`http://app.seepersad.org:8002/email/`,
        {
          'email': form_data.email,
          'name': form_data.name,
          'message': form_data.message,
          'subject': "Royalty Club Contact Form"
        })
        .then(res => {
          if (res.status) {
            setApiResponse("Message Sent");
          }
          else {
            setApiResponse("Ran into some problems, email us directly");
          }
          // console.log(res);
          // console.log(res.data);
        })
    } catch (error) {
      setApiResponse("Dread, wtf... contact the admin");
      console.log(error);
    }
  };

  return (
    <section className="contact-section sections-pd bg-black">
      <div className="word-row-section word-row-white">
        <span>Get In Touch</span>
      </div>
      <div className="emement-bg" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="h3-white title-section">Get In Touch</h3>
          </div>
          <div className="col-md-8">
            <div className="well">
              <form id="contactForm" data-toggle="validator" className="shake" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  {
                    /* ENTER MANE */
                  }
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter name"
                    ref={register({
                      required: <p>Please enter your name</p>
                    })}

                  />
                  {errors.name && errors.name.message}<br />

                  <div className="help-block with-errors" />
                </div>
                <div className="form-group">
                  {
                    /* ENTER EMAIL */
                  }
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    // required data-error="Please enter your email address"
                    ref={register({
                      required: <p>Enter an email address</p>,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: <p>Invalid email address</p>
                      }
                    })}

                  />
                  {errors.email && errors.email.message}<br />

                  <div className="help-block with-errors" />
                </div>
                <div className="form-group">
                  {
                    /* ENTER MESSAGE */
                  }
                  <textarea
                    id="message"
                    className="form-control"
                    rows={5}
                    placeholder="Enter your message"
                    // required data-error="Please enter your message"
                    name="message"
                    defaultValue={""}
                    ref={register({
                      required: <p>Enter a message, show us some love</p>
                    })}
                  />
                  {errors.message && errors.message.message}<br />

                  {/* <input type="submit" className="form-button-ia" /> */}


                  <div className="help-block with-errors" />
                </div>

                <div className="form-error-ia">
                  <p> {api_response} </p>
                </div>

                {
                  /* button form submit */
                }
                <div className="submit">
                <div className="content-button">
                  <button id="form-submit" className="button">
                    <span className="wrap-button-link submit-button">
                      <span>send message</span>
                      <span className="button-link">send message</span>
                    </span>
                  </button>
                </div>
                <div id="msgSubmit" className="h5 h5 text-danger hidden text-center" />
              </div>
              </form>
            </div>
          </div>
          {
            /* /.col-md-8 */
          }
          <div className="col-md-4 contact-info">
            <ul>
              <li>
                <p>
                  <span>Address</span> - {props.ContactInfo.Address}{" "}
                    </p>
              </li>
              <li>
                <p>
                  <span>Phone</span> - {props.ContactInfo.Phone}{" "}
                    </p>
              </li>
              <li>
                <p>
                  <span>E-mail</span> - {props.ContactInfo.Email}{" "}
                </p>
              </li>
            </ul>
          </div>
          {
            /* /.col-md-8 */
          }
        </div>
        {
          /* /.row */
        }
        {/* <div className="row"> */}
          {/* <div className="col-md-12"> */}
            {
              /* Google Map */
            }
            {/* <div className="googlemap"> */}
              {/* <div id="map" /> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
        {
          /* /.row */
        }
      </div>
      {
        /* /.container */
      }
    </section>)
};


class Contact extends Component {
  constructor(props) {
    // Gives the "App" all the "Component" super powers
    super(props); // Inherits all the models which exist in Component

    this.state = {
      homepage: this.props.homepage,
      base_url: this.props.base_url

    };
  }

  componentDidMount() {
    window.fnInitMain();
  }

  render() {
    const { homepage, base_url } = this.state; // Load states locally as constants, to avoid using "this.state"

    return (
      <div>
        <Navigation />
        <Header homepage={homepage} base_url={base_url} page_title="Contact" />
        <Tagline />
        <ContactForm ContactInfo={homepage.ContactInfo}/>
        <TaglineBase />
        <Footer SocialLinks = {homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={false} />
      </div>
    );
  }
}

export default Contact;
