import React from "react";
import Logo from './logo';


const SliderCard = (props) => {

  const image_url = props.base_url + props.slider_data.Image[0].url;
  return(
  <div className="swiper-slide">
    <div className="home-full-screen-slider-img" >
      <div className="home-slider-img-1" style={{ backgroundImage: `url(${image_url})` }} />
    </div>
    <div className="slider-txt">
      <h4 className="h4-white anim-el anim-el-on animated fadeInUp delay-03s">{props.slider_data.MainHeading}</h4>
      <h1 className="anim-el anim-el-on animated fadeInUp delay-01s">{props.slider_data.SubHeading}</h1>
      <p className="anim-el anim-el-on animated fadeInUp delay-04s rc-slider-description">{props.slider_data.Description}</p>
      <div className="anim-el anim-el-on animated fadeInUp delay-06s">
        <a href={props.slider_data.Link} className="wrap-button-link">
          <span>{props.slider_data.LinkText}</span>
          <span className="button-link">read more</span>
        </a>
      </div>
    </div>
  </div>
  )
};

export default props => {
  const { base_url, homepage } = props;

  return (
    <header>
      <Logo base_url={base_url} url={homepage.ContactInfo.FooterLogo.url}> </Logo>
      {/* Slider main container */}
      <div className="wrap-header-slide">
        <div className="swiper-container home-slider">
          {/* Additional required wrapper */}
          <div className="swiper-wrapper">
            {/* Slides */}
            {homepage.HomeFullScreenSlider[0].ImageSlide.map((slider_data,index)=>
              <SliderCard key={index} slider_data={slider_data} base_url={base_url}/>
              )}
          </div>{/* /.swiper-wrapper */}
        </div>{/* /.swiper-container */}
      </div>{/* /.wrap-header-slide */}
      {/* If we need navigation buttons */}
      <div className="swiper-button-prev"><i className="ion-ios-arrow-back" /></div>
      <div className="swiper-button-next"><i className="ion-ios-arrow-forward" /></div>
      {/* Add Pagination */}
      <div className="counter">
        <div className="swiper-pagination custom-pagination">
        </div>
      </div>
    </header>)
};
