import React from "react";
import { Link } from "react-router-dom";

/**
 * Tagline component manages two lines of 
 */

export default props => {
  var button_text;
  const {destination = 'works'} = props;
  if (props.button_text) {
    button_text = <Link 
                  className="wrap-button-link"
                    onClick={() => (window.location.href = ("/"+ destination))}
                    to={"/"+destination}>
                    <span>{props.button_text}</span>
                    <span className="button-link">{props.button_text}</span>
                  </Link>
  }
  else {
    button_text = null;
  }

  return (
    <div className="tagline-section bg-yellow">
      <div className="container text-center">
        <h2>{props.taglineH2FirstLine}
          <br/>{props.taglineH2SecondLine}
        </h2>
        {button_text}
      </div>
    </div>
  )
}