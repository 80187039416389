import React, { Component } from "react";
import { Link } from "react-router-dom";
import SocialFooter from './SocialFooter';


class Footer extends Component {

  constructor(props) {
    super(props); // Inherits all the models which exist in Component


    this.state = {
      triggerFunction: props.triggerFunction
    };

  }

  componentDidMount() {
    if (this.state.triggerFunction) {
      window.fnInitMain();
    }
  }

  render() {
    const { Copyright, ContactInfo, base_url, SocialLinks } = this.props

    return (
      <div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
              <div className="footer-logo">
                  <img
                    src={
                      base_url +
                      ContactInfo.FooterLogo.url
                    }
                    alt="logo"
                  />{" "}
                </div></div>
                <div className="col-md-4">
                <h5>{ContactInfo.FooterTitle} </h5>
                <ul >
                  <li > <p><Link  onClick={() => (window.location.href = "/")}        to="/"       > Home  </Link></p></li>
                  <li> <p><Link  onClick={() => (window.location.href = "/about")}   to="/about"  > About </Link></p></li>
                  <li> <p><Link  onClick={() => (window.location.href = "/works")}   to="/works"  > Works </Link></p></li>
                  {/* <li> <p><Link  onClick={() => (window.location.href = "/news")}    to="/news"   > News  </Link></p></li> */}
                  <li> <p><Link  onClick={() => (window.location.href = "/contact")} to="/contact"> Contact </Link></p></li>
                </ul>
                <br></br>
              </div>

              <div className="col-md-4">
                  {ContactInfo.FooterLogo.caption}{" "}
                <h5>Contacts</h5>
                <ul>
                  <li><p><span>Address</span> - {ContactInfo.Address}{" "}</p></li>
                  <li><p><span>Phone</span> - {ContactInfo.Phone}{" "}</p></li>
                  <li><p><span>E-mail</span> - {ContactInfo.Email}{" "}</p></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-12 copyright-content">
                  <span className="copyright-text">
                    {Copyright.CopyrightText}
                  </span>
                  <SocialFooter SocialLinks={SocialLinks}/>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}


export default Footer;