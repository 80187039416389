import React, { Component } from "react";
import Navigation from "../components/navigation"
import Tagline from '../components/tagline';
import Footer from "../components/footer";
import api from '../api'
import Logo from '../components/logo';
import { Link } from "react-router-dom";
import RecentWorks from '../components/recentworks';

import Loading from '../components/loading';

const LocalHeader = (props) => (
  <header className="header-pages">
    <Link onClick={() => window.location.href = "/"} to={"/"}>
      <Logo base_url={props.base_url} url={props.url}> </Logo>
    </Link>

    <div className="pages-bg">
      <div className="group-pages">
        <div className="word-row-pages">
          <span>"Our News"</span>
        </div>
      </div>
    </div>
    <div className="page-name">
      <span>"Our News"</span>
    </div>
  </header>
);



const AboutMe = () => (
  <div className="blog-about-me">
    <img className="img-fluid" src="assets/img/works/7.jpg" alt="img" />
    <h5>About me</h5>
    <p>
      Hic distinctio autem iure obcaecati explicabo!Placeat
      expedita earum magni, eum? Impedit modi nam nulla
      explicabo eius, ut accusantium tenetur fugiat laborum
      adipisci molestiae maiores expedita laboriosam, commodi
      illo velit quos quisquam eum iusto? In, obcaecati.
                      </p>
  </div>
);



const BlogCategories = () => (
  <div className="blog-categories">
    <h5>Blog Categories</h5>
    <div className="row">
      <div className="col-lg-6">
        <ul className="list-unstyled">
          <li>
            <a href="blog-home.html">Travels</a>
          </li>
          <li>
            <a href="blog-home.html">Business</a>
          </li>
          <li>
            <a href="blog-home.html">Photography</a>
          </li>
          <li>
            <a href="blog-home.html">Lifestyle</a>
          </li>
          <li>
            <a href="blog-home.html">Fashion</a>
          </li>
        </ul>
      </div>
      {
        /* /.col-lg-6 */
      }
    </div>
    {
      /* /.row */
    }
  </div>
);



const Tags = () => (
  <div className="tags">
    <h5>Tags</h5>
    <div className="row">
      <div className="col-lg-12">
        <ul className="sidebar-tags list-unstyled">
          <li>
            <a href="blog-home.html">Travels</a>
          </li>
          <li>
            <a href="blog-home.html">Business</a>
          </li>
          <li>
            <a href="blog-home.html">Photography</a>
          </li>
          <li>
            <a href="blog-home.html">Lifestyle</a>
          </li>
          <li>
            <a href="blog-home.html">Fashion</a>
          </li>
        </ul>
      </div>
      {
        /* /.col-lg-12 */
      }
    </div>
    {
      /* /.row */
    }
  </div>
);



const PageArrows = () => (
  <div className="row arrows-section">
    <div className="col-md-12">
      <div className="arrows-obj">
        <a className="arrow-left" href>
          <img src="assets/custom-icon/left.svg" alt="img" />
        </a>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item active">
              <a className="page-link" href="/">
                1
                        </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                2
                        </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                3
                        </a>
            </li>
          </ul>
        </nav>
        <a className="arrow-right" href>
          <img src="assets/custom-icon/right.svg" alt="img" />
        </a>
      </div>
    </div>
    {
      /* /.col-md-12 */
    }
  </div>
);



const OtherNews = () => (
  <section className="recent-works-section sections-pd bg-white">
    <div className="word-row-section word-row-black">
      <span>Other News</span>
    </div>
    <div className="emement-bg" />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3 className="h3-black title-section">Other News</h3>
        </div>
      </div>
    </div>
    {
      /* /.container */
    }
    <div className="container-fluid">
      <div className="row">
        {
          /* Slider main container */
        }
        {
          /* Swiper */
        }
        <div className="swiper-container other-news-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/1.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/2.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/3.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/4.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/5.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/6.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/7.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
            <div className="swiper-slide">
              <a href="single-news.html" title="Title">
                <div className="img-news">
                  <img className="img-fluid" src="assets/img/news/other-news/8.jpg" alt="img" />
                </div>
                <div className="info">
                  <h5 className="head-text-img">Heading here</h5>
                </div>
              </a>
            </div>
            {
              /* /.swiper-slide */
            }
          </div>
          {
            /* /.swiper-wrape */
          }
          {
            /* Add Pagination */
          }
          <div className="swiper-pagination" />
        </div>
        {
          /* /.swiper-container */
        }
      </div>
      {
        /* /.row */
      }
    </div>
    {
      /* /.container-fluid */
    }
  </section>
);



const RecentPosts = () => (
  <div className="recent-post">
    <h5>Recent Posts</h5>
    <div className="row">
      <div className="col-lg-9 col-md-8 col-sm-9 col-xs-9">
        <a href="single-post.html">Libero excepturi fuga.</a>
        <p className="date-post">July 16, 2017 at 16:10 PM</p>
      </div>
    </div>
    <div className="row post-1">
      <div className="col-lg-9 col-md-8 col-sm-9 col-xs-9">
        <a href="single-post.html">Libero excepturi fuga.</a>
        <p className="date-post">July 16, 2017 at 16:10 PM</p>
      </div>
    </div>
    <div className="row post-2">
      <div className="col-lg-9 col-md-8 col-sm-9 col-xs-9">
        <a href="single-post.html">Libero excepturi fuga.</a>
        <p className="date-post">July 16, 2017 at 16:10 PM</p>
      </div>
    </div>
  </div>
);



const Search = () => (
  <div className="input-group">
    <input type="text" className="form-control" placeholder="Search" />
  </div>
);



const SideBar = () => (
  <div className="col-md-4">
    <div className="news-sidebar">
      <div className="blog-sidebar">
        <Search />
        <AboutMe />
        <BlogCategories />
        <RecentPosts />
        <Tags />
      </div>
    </div>
  </div>
);



const NewsSection = (props) => {

  if (props.news.length === 0) { // Keep loading until API get returns data
    return (
      <Loading />
    )
  }
  else {
    return (
      <section className="news-section sections-pd bg-black">
        <div className="word-row-section word-row-white">
          <span>Latest News</span>
        </div>
        <div className="emement-bg" />
        <div className="container">
          <div className="row">
            {
              /* Name Section */
            }
            <div className="col-md-12">
              <h3 className="h3-white title-section">Latest News</h3>
            </div>
            {
              /* Posts Section */
            }
            <div className="col-md-8">
              {
                /* Latest Posts */
              }
              <div className="latest-post">
                <div className="latest-post-info">
                  <a className="news-tag" href="/">
                    # travels
                    </a>
                  <div className="post-date">
                    <span>July 15, 2018 at 13:10 PM</span>
                  </div>
                </div>
                {
                  /* Img Post */
                }
                <img className="img-fluid" src="assets/img/news/latest-news/1.jpg" alt="img" />
                <div className="latest-post-text">
                  <a href="single-post.html">
                    <h4 className="h4-white">
                      {props.news[0].PostName}
                      </h4>
                  </a>
                  <p>
                      {props.news[0].PostText}
                    </p>
                  {
                    /* button */
                  }
                  <a href="single-news.html" className="wrap-button-link">
                    <span>read more</span>
                    <span className="button-link">read more</span>
                  </a>
                </div>
              </div>
            </div>
            {/* <SideBar /> */}
          </div>
          <PageArrows />
        </div>
      </section>
    )
  }
};


class News extends Component {
  constructor(props) {
    // Gives the "App" all the "Component" super powers
    super(props); // Inherits all the models which exist in Component

    this.state = {
      homepage: this.props.homepage,
      base_url: this.props.base_url,
      news: [],
      cache_id: this.props.cache_id,
      works: this.props.works

    };
  }

  componentDidMount() {
    window.fnInitMain();
    this.getDetails('news', 'news-posts');
  }

  getDetails = (assigned_state, url) => {
    // Retrieve the data from localStorage
    // Reference: https://www.robinwieruch.de/local-storage-react
    const retrievedObject = localStorage.getItem(assigned_state);
    const parsedObject = JSON.parse(retrievedObject);

    // If there is no data in the localStorage, then get it
    if (parsedObject === null) {
      api.getData(url)
        .then(results => {
          const strapi_api_get_data = results.data;
          localStorage.setItem('cache_id', this.state.cache_id);
          localStorage.setItem(assigned_state, JSON.stringify(strapi_api_get_data));
          this.setState({ [assigned_state]: strapi_api_get_data });
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
    // The object existed in localStorage, so let's use it
    else {
      this.setState({ [assigned_state]: parsedObject });
    }
  };


  render() {
    const { homepage, base_url, works, news } = this.state; // Load states locally as constants, to avoid using "this.state"

    return (
      <div>
        <Navigation />
        <LocalHeader url={homepage.ContactInfo.FooterLogo.url} base_url={base_url} />
        <Tagline taglineH2FirstLine="We design and build beautiful brands" taglineH2SecondLine={homepage.taglineH2SecondLine} />
        <NewsSection news={news} />
        {/* <OtherNews /> */}
        <RecentWorks works={works} base_url={base_url} />
        <Footer SocialLinks = {homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={false} />

      </div>
    );
  }
}

export default News;
