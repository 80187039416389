import React from 'react';
import Logo from './logo';
import { Link } from "react-router-dom";

export const Header = (props) => {
  const { base_url, homepage, page_title } = props;
  return (
  <header className="header-pages">
    <Link
    onClick={() => (window.location.href = "/")}
    to={"/"}
    >
      <Logo base_url={base_url} url={homepage.ContactInfo.FooterLogo.url}> </Logo>
    </Link>

    <div className="pages-bg">
      <div className="group-pages">
        <div className="word-row-pages">
          <span>{page_title}</span>
        </div>
      </div>
    </div>
    <div className="page-name">
      <span>{page_title}</span>
    </div>
  </header>
  );
};
