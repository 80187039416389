import React, { Component } from 'react';
import Footer from "../components/footer";
import Navigation from "../components/navigation"
import RecentWorks from '../components/recentworks';
import { Header } from '../components/Header';
import Tagline from '../components/tagline';

const NavigationArrows = () => (
  <div className="row arrows-section">
    <div className="col-md-12">
      <div className="arrows-obj">
        <a className="arrow-left" href><img src="assets/custom-icon/left.svg" alt="img" /></a>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item active"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
          </ul>
        </nav>
        <a className="arrow-right" href="single-project.html"><img src="assets/custom-icon/right.svg" alt="img" /></a>
      </div>
    </div>
  </div>
);



const ProjectHeader = (props) => (
  <div className="col-md-6">
    <h3 className="h3-white title-section">{props.project.ProjectName}</h3>
    <h4 className="h4-white subtitle-section">{props.project.ProjectDescription}</h4>
  </div>
);



const ProjectAttributes = (props) => (
  <div className="project-info">
    <p><span className="sub-title">Date:</span>{props.project.ProjectDate}</p>
    <p><span className="sub-title">Client:</span>{props.project.Client}</p>
    <p><span className="sub-title">Website:</span><a href="#">{props.project.Website}</a></p>
  </div>
);


class WorkSection extends Component {
  render() {

    const { base_url, project } = this.props;

    return (

      <section className="works-section sections-pd bg-black">
        <div className="word-row-section word-row-white"><span>{project.ProjectName}</span></div>
        <div className="emement-bg" />
        <div className="container">
          <div className="row">
            <ProjectHeader project={project}></ProjectHeader>
          </div>
          <div className="row single-portfolio-section">
            <div className="col-md-12">
              <ProjectAttributes project={project}></ProjectAttributes>
            </div>
            {
              /* Grid portfolio */
            }
            <div className="col-md-12">
              <a className="image-link-home" href={`${base_url + project.ProjectImages[0].url}`} title={project.ProjectName}>
                <div className="zoom-icon"><i className="ion-ios-search" /></div>
                <img className="img-fluid" src={`${base_url + project.ProjectImages[0].url}`} alt="img" />
              </a>
            </div>
            {/* <div className="col-md-6">
              <a className="image-link-home" href={`${base_url + project.ProjectImages[1].url}`} title={project.ProjectName}>
                <div className="zoom-icon"><i className="ion-ios-search" /></div>
                <img className="img-fluid" src={`${base_url + project.ProjectImages[1].url}`} alt="img" />
              </a>
            </div> */}
            {/* <div className="col-md-6">
              <a className="image-link-home" href={`${base_url + project.ProjectImages[2].url}`} title={project.ProjectName}>
                <div className="zoom-icon"><i className="ion-ios-search" /></div>
                <img className="img-fluid" src={`${base_url + project.ProjectImages[2].url}`} alt="img" />
              </a>
            </div> */}
            {/* <div className="col-md-12">
              <a className="image-link-home" href={`${base_url + project.ProjectImages[3].url}`} title={project.ProjectName}>
                <div className="zoom-icon"><i className="ion-ios-search" /></div>
                <img className="img-fluid" src={`${base_url + project.ProjectImages[3].url}`} alt="img" />
              </a>
            </div> */}
          </div>
          {/* <NavigationArrows /> */}
        </div>
      </section>
    )
  }
}



class Projects extends Component {

  componentDidMount() {
    window.scrollTo(0, 0); // https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
    window.fnInitMain();
  }

  render() {

    const { homepage, base_url, works, projects } = this.props;

    return (
      <div>
        <Navigation />
        <Header homepage={homepage} base_url={base_url} page_title="Project"/>
        <Tagline taglineH2FirstLine="We develop a useful product" taglineH2SecondLine="based on human needs."/>
        <WorkSection project={projects} base_url={base_url} />
        <RecentWorks works={works} base_url={base_url} />
        <Footer  SocialLinks = {homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={false} />
      </div>
    )
  }
}
export default Projects;