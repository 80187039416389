import React, { Component } from "react";

import 'react-animated-slider/build/horizontal.css';
import api from '../api' // Manages the axios api handling

import Footer from '../components/footer';
import Loading from '../components/loading';
import SliderDesktop from "../components/slider";
import SliderMobile from "../components/sliderMobile";
import Navigation from "../components/navigation"
import Header from '../components/header_slider';
import Services from '../components/services';
import RecentWorks from '../components/recentworks';
import Testimonials from '../components/testimonials';
import Tagline from '../components/tagline';
import Funfacts from '../components/funfacts';
import AboutSection from '../components/aboutsection';

import { BrowserView, MobileView } from 'react-device-detect';

// Component decides if it is a mobile or desktop view and then renders accordingly
// Props are passed in and then assigned to the local state
// There are two slider components, one for desktop and the other for mobile which are configured differently
class ConditionalSlider extends Component {

  render() {
    const { clients, base_url } = this.props; // Load states locally as constants, to avoid using "this.state"

    return (
      <>
        <BrowserView>
          <SliderDesktop merchants={clients} base_url={base_url} />
        </BrowserView>
        <MobileView>
          <SliderMobile merchants={clients} base_url={base_url} />
        </MobileView>
      </>
    );
  }
};

class Home extends Component {
  constructor(props) {
    // Gives the "App" all the "Component" super powers
    super(props); // Inherits all the models which exist in Component

    this.state = {
      homepage: this.props.homepage,
      clients: [],
      testimonials: [],
      works: this.props.works,
      base_url: this.props.base_url,
      cache_id: this.props.cache_id
    };
  }

  componentDidMount() {

    window.fnInitMain();
    this.getDetails('clients', 'clients');
    this.getDetails('testimonials', 'testimonials');

  }

  getDetails = (assigned_state, url) => {
    // Retrieve the data from localStorage
    // Reference: https://www.robinwieruch.de/local-storage-react
    const retrievedObject = localStorage.getItem(assigned_state);
    const parsedObject = JSON.parse(retrievedObject);

    // If there is no data in the localStorage, then get it
    if (parsedObject === null) {
      api.getData(url)
        .then(results => {
          const strapi_api_get_data = results.data;
          localStorage.setItem('cache_id', this.state.cache_id);
          localStorage.setItem(assigned_state, JSON.stringify(strapi_api_get_data));
          this.setState({ [assigned_state]: strapi_api_get_data });
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
    // The object existed in localStorage, so let's use it
    else{
      this.setState({ [assigned_state]: parsedObject });
    }
  };


  render() {

    const { clients, homepage, base_url, testimonials, works } = this.state; // Load states locally as constants, to avoid using "this.state"

    if ((clients.length === 0) ||
    (testimonials.length === 0)  ) { // Keep loading until API get returns data
      return (
        <Loading />
      )
    }
    else {
      return (
        <div>
          <Navigation />
          <Header homepage={homepage} base_url={base_url} />
          <Tagline taglineH2FirstLine= {homepage.taglineH2FirstLine} taglineH2SecondLine= {homepage.taglineH2SecondLine}/>
          <AboutSection About={homepage.About} base_url={base_url}/>
          <Services homepage={homepage} />
          <RecentWorks works={works} base_url={base_url}/>
          {/* <Funfacts homepage={homepage}></Funfacts> */}
          <Testimonials testimonials={testimonials} base_url={base_url}/>
          <ConditionalSlider clients={clients} base_url={base_url} />
          <Footer SocialLinks = {homepage.socialLinks} ContactInfo={homepage.ContactInfo} Copyright={homepage.Copyright} base_url={base_url} triggerFunction={true} />
        </div>
      );
    }
  }
}

export default Home;