import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Home from './pages/home';
import About from './pages/about';
import Works from './pages/works';
import News from './pages/news';
import Contact from './pages/contact';
import api from './api' // Manages the axios api handling
import Loading from './components/loading';
import Projects from './pages/projects';
// import SingleProject from './pages/single-project';

class Routing extends Component {
  constructor() {
    // Gives the "App" all the "Component" super powers
    super(); // Inherits all the models which exist in Component

    this.state = {
      homepage: [],
      works:    [],
      projects: [],
      about: [],
      base_url: "https://cms.royalty-club.com",
      cache_id: 17
    };
  }

  componentDidMount() {

    const localStorage_cache_id = localStorage.getItem("cache_id");
    if ((localStorage_cache_id !== null) &&
         (this.state.cache_id > localStorage_cache_id)) {
        localStorage.clear();
        console.log("Clearing cache")
    }

    this.getDetails('homepage', 'homepage');
    this.getDetails('works', 'works');
    this.getDetails('projects', 'projects');
    this.getDetails('about', 'about');
  }

  getDetails = (assigned_state, url) => {
    // Retrieve the data from localStorage
    // Reference: https://www.robinwieruch.de/local-storage-react
    const retrievedObject = localStorage.getItem(assigned_state);
    const parsedObject = JSON.parse(retrievedObject);

    // If there is no data in the localStorage, then get it
    if (parsedObject === null) {
      api.getData(url)
        .then(results => {
          const strapi_api_get_data = results.data;
          localStorage.setItem('cache_id', this.state.cache_id);
          localStorage.setItem(assigned_state, JSON.stringify(strapi_api_get_data));
          this.setState({ [assigned_state]: strapi_api_get_data });
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    }
    // The object existed in localStorage, so let's use it
    else{
      this.setState({ [assigned_state]: parsedObject });
    }
  };

  render() {
    const { homepage, base_url, works, projects,cache_id,about } = this.state; // Load states locally as constants, to avoid using "this.state"

    if ((homepage.length === 0) || (works.length === 0) || (projects.length === 0)|| (about.length === 0)) { // Keep loading until API get returns data
      return (
        <Loading />
      )
    }
    else {
      return (
        <Router>
          <div>
            {/* When passing props you need to render
            https://til.hashrocket.com/posts/z8cimdpghg-passing-props-down-to-react-router-route */}
            <Switch>
              <Route path="/about"
                render={() => (
                  <About homepage={homepage} base_url={base_url} about={about} cache_id={cache_id}/>
                )}
              />

              <Route path="/works"
                render={() => (
                  <Works homepage={homepage} projects={projects} base_url={base_url} cache_id={cache_id} about={about}/>
                )}
              />

              {/* <Route path="/news"
                render={() => (
                  <News homepage={homepage} base_url={base_url} cache_id={cache_id} works={works}/>
                )}
              /> */}

              <Route path="/contact"
                render={() => (
                  <Contact homepage={homepage} base_url={base_url} cache_id={cache_id}/>
                )}
              />

              <Route exact path="/"
                render={() => (
                  <Home homepage={homepage} base_url={base_url} works={works} cache_id={cache_id}/>
                )}
              />

              {projects.map((project, index) => {
                const path = "/projects_" + project.ProjectName;
                return <Route path={path} key={index}
                  render={() => (
                    <Projects key={index} homepage={homepage} base_url={base_url} works={works} projects={project} cache_id={cache_id}/>
                  )}
                />

              })}

            </Switch>

          </div>
        </Router>
      )
    }
  }
}

ReactDOM.render(<Routing />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
