import React from "react";

function Loading(props) {
    return (
        <div id="loader-wrapper">
        <div className="loader-txt">
          <div id="loader" data-text="LOADING...">
            LOADING...
          </div>
        </div>
        <div className="loader-section section-left" />
        <div className="loader-section section-right" />
      </div>
    );
}

export default Loading;